<template>
  <div class="layout" id="layout">
    <Incident v-if="showIncident" @hideIncident="IncidentHide" />
    <div class="header">
      <div class="header-left">
        <img :src="Logo" />
        <span  style="font-weight: bolder; color: #fff; font-size: 22px">
          森林防火无人机视频图传平台 |  福安市林业局
        </span>
      </div>
      <div class="header-right">
        <!-- <div class="theme-btns">
          <div style="background: #1f1f1f; color: #c9caca"
               @click="theme(0)">
            夜间
          </div>
          <div style="background: #f0f0f0; color: #000"
               @click="theme(1)">
            日间
          </div>
        </div> -->
        <span class="entranceJL" v-if="$JL" @click="JilinProvinceBut">大数据平台</span>
        <Tooltip
            :content="UserData.name"
            style="height: 26px; margin-right: 25px"
            v-if="userType !== 0"
        >
          <div class="headImg">
            <img
                alt="用户头像"
                :src="UserData.head_icon"
                @click="EditUserShou = !EditUserShou"
            />
          </div>
        </Tooltip>
        <div class="incident-btn" v-if="$CSXF" @click="IncidentShow">
          <Icon type="ios-folder-open-outline" color="#fff" size="18" />
        </div>
        <!-- <Button type="primary"
                style="min-width:1.08rem;height:0.32rem;fontSize:0.14rem"
                icon="ios-photos"
                @click="IncidentShow">
          {{incidentName?incidentName : '事件选择'}}<Icon type="ios-folder-open-outline" />
        </Button> -->
        <!-- <span style="cursor: pointer"
              @click="EditUserShou = !EditUserShou">{{
          UserData.name
        }}</span> -->
        <div class="exit" @click="exit()">
          <img src="@/assets/img/Icon/exit.png" srcset />
        </div>
      </div>
    </div>

    <div class="layoutSidebar">
      <!-- 团队选择开关 -->
      <div
        v-if="this.user_info.permission != 0"
        :class="['team-cascader', teamShow ? '' : 'disable-team']"
        @click="showDrawer"
      >
        <img src="@/assets/img/Icon/side_pull.png" alt="" />
      </div>
      <!-- 侧边导航 -->
      <div class="sidebar">
        <div
          :class="
            $CSXF
              ? changeRed == ind
                ? 'select'
                : ''
              : changeRed == ind
              ? 'public_select'
              : ''
          "
          v-for="(item, ind) in MenuList"
          :key="ind"
          @click="MenuSwitchover(item, ind)"
        >
          <div :class="$CSXF ? 'item' : 'public_item'">
            <img
              :src="
                changeRed == ind
                  ? $store.state.darkTheme
                    ? item.img
                    : item.img_press_light
                  : $store.state.darkTheme
                  ? item.img
                  : item.img_light
              "
              :alt="item.name"
            />
            <span
              :style="{
                color:
                  changeRed == ind
                    ? $store.state.darkTheme
                      ? '#fff'
                      : '#2182cf'
                    : '#9c9ea0',
              }"
              >{{ item.name }}</span
            >
          </div>
          <div class="bar-child" v-if="item.children && item.children.length">
            <div
              v-for="(child, cIndex) in item.children"
              :key="cIndex"
              @click.stop="setActiveMenu(cIndex, child)"
              :class="
                activeChildMenu === cIndex && changeRed === ind ? 'active' : ''
              "
            >
              <span>{{ child.name }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="Content" v-if="isRouteRefresh">
        <router-view :UserData="UserData" :websock="websock"></router-view>
      </div>
    </div>

    <!--弹窗区-->
    <Modal
      v-model="EditUserShou"
      class-name="EditUser-modal"
      @on-cancel="closeEditUser"
    >
      <p slot="header" style="text-align: center">
        <span>编辑用户</span>
      </p>
      <div style="padding: 10px 30px">
        <Form
          ref="EditUser"
          :model="EditUser"
          :rules="ruleValidate"
          label-position="top"
        >
          <div style="display: flex">
            <FormItem label="修改用户头像 :" prop="head_icon_Edit">
              <div
                class="upload-list"
                v-for="(item, idx) in uploadList"
                :key="idx"
              >
                <template v-if="item.status === 'finished'">
                  <img :src="item.path + item.url" />
                  <div class="upload-list-cover">
                    <Icon
                      type="ios-trash-outline"
                      color="#fff"
                      @click="handleRemove_Edit(item)"
                    ></Icon>
                  </div>
                </template>
              </div>
              <Upload
                v-if="uploadList.length == 0"
                ref="upload"
                :show-upload-list="false"
                :format="['jpg', 'jpeg', 'png']"
                :max-size="2048"
                :on-format-error="handleFormatError"
                :on-exceeded-size="handleMaxSize"
                :before-upload="uploadBefore_Edit"
                type="drag"
                action
                style="display: inline-block; width: 80px"
              >
                <div
                  style="
                    width: 80px;
                    height: 80px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #e0e0e0;
                  "
                >
                  <img :src="EditUser.head_icon" style="width: 100%" alt="" />
                </div>
              </Upload>
            </FormItem>
            <div class="bind-info" v-if="$public">
              <div v-if="userPhone">
                <p>
                  手机号:{{ userPhone
                  }}<Button
                    type="primary"
                    size="small"
                    style="marginleft: 20px; verticalalign: baseline"
                    @click="unboundModal = true"
                    >解除绑定</Button
                  >
                </p>
              </div>
              <Button
                type="primary"
                size="small"
                @click="bindModal = true"
                v-else
                >绑定手机</Button
              >
            </div>
          </div>
          <FormItem label="修改用户名 :" prop="name">
            <Input v-model.trim="EditUser.name"></Input>
          </FormItem>
          <FormItem label="是否修改用户密码 :">
            <RadioGroup
              v-model="changePasswordType"
              @on-change="isChangePassword"
            >
              <Radio :label="1">是</Radio>
              <Radio :label="0">否</Radio>
            </RadioGroup>
          </FormItem>
          <div v-if="changePasswordType === 1">
            <FormItem label="用户原密码 :" prop="OldPassword">
              <Input
                v-model.trim="EditUser.OldPassword"
                type="password"
              ></Input>
            </FormItem>
            <FormItem label="新的用户密码 :" prop="NewPassword">
              <Input
                v-model.trim="EditUser.NewPassword"
                type="password"
              ></Input>
            </FormItem>
            <FormItem label="再次输入新的密码 :" prop="checkNewPassword">
              <Input
                v-model.trim="EditUser.checkNewPassword"
                type="password"
              ></Input>
            </FormItem>
          </div>
        </Form>
      </div>
      <div style="text-align: center" slot="footer">
        <Button
          type="info"
          @click="EditUser_ok"
          style="width: 200px; letter-spacing: 8px"
          >确认</Button
        >
      </div>
    </Modal>
    <Modal
      v-model="unboundModal"
      @on-ok="okUnbound"
      width="360"
      @on-cancel="cancelUnbound"
      title="解除绑定"
    >
      <div class="securityBox">
        <Input
          type="text"
          v-model.trim="EditUser.smscode"
          placeholder="短信验证码"
        ></Input>
        <div class="security" v-if="!reckon_time_shou" @click="sendSecurity">
          发送验证码
        </div>
        <div
          class="security"
          :class="reckon_time_ent ? 'bgc' : ''"
          v-if="reckon_time_shou"
          @click="sendSecurity"
        >
          重新发送
          <span v-if="reckon_time_ent">( {{ reckon_time }}s )</span>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="bindModal"
      @on-ok="okbind"
      width="360"
      @on-cancel="cancelbind"
      title="绑定手机号"
    >
      <div class="securityBox">
        <Input
          type="text"
          v-model.trim="EditUser.phone"
          style="marginbottom: 15px"
          placeholder="请输入手机号"
        ></Input>
        <Input
          type="text"
          v-model.trim="EditUser.smscode"
          placeholder="短信验证码"
        ></Input>
        <div class="security" v-if="!reckon_time_shou" @click="sendSecurity">
          发送验证码
        </div>
        <div
          class="security"
          :class="reckon_time_ent ? 'bgc' : ''"
          v-if="reckon_time_shou"
          @click="sendSecurity"
        >
          重新发送
          <span v-if="reckon_time_ent">( {{ reckon_time }}s )</span>
        </div>
      </div>
    </Modal>
    <!-- 语音通话>>>>> -->
    <!-- <Modal
      v-model="$store.state.isShowNewInvitationDialog"
      title="通话邀请"
      ok-text="接听"
      cancel-text="拒绝"
      @on-ok="ok"
      @on-cancel="cancel"
    >
      <p>{{ this.getNewInvitationDialogContent() }}</p>
    </Modal> -->
    <div class="onPhone" v-if="$store.state.isShowNewInvitationDialog">
      <div>
        <img style="margin: 0 10px" :src="myicon.phone" alt="" />
        <span>{{ this.getNewInvitationDialogContent() }}</span>
        <div>
          <a style="margin: 0 20px" href="javascript:;"
            ><img :src="myicon.callOn" alt="" @click="ok()"
          /></a>
          <a href="javascript:;"
            ><img :src="myicon.callOff" alt="" @click="cancel()"
          /></a>
        </div>
      </div>
    </div>

    <div v-show="$store.state.showVideo" class="callvideo">
      <div>
        <img :src="myicon.phoneOn" alt="" />
      </div>
      <div class="callspan">
        <span> 通话中... </span>
      </div>
      <div>
        <img :src="myicon.phoneOff" alt="" @click="hang()" />
      </div>
    </div>
    <!-- <<<<<语音通话 -->

    <Drawer
      width="350"
      placement="left"
      :closable="false"
      @on-close="teamValueCloseBut"
      :draggable="true"
      :styles="{
        overflow: 'auto',
        paddingBottom: '53px',
        position: 'static',
        background: $store.state.darkTheme ? '#1f1f1f' : '#f0f0f0',
      }"
      v-model="teamValue"
    >
      <div slot="header" class="drawer-header">
        <span>团队选择</span>
        <div @click="selfTeam" v-if="user_info.permission == 1 && !this.$CSTJ">
          回到本团队
        </div>
      </div>
      <div v-if="user_info.permission == 0">无查看下级团队权限</div>
      <TeamTreeCascader ref="teamTree" v-if="user_info.permission == 1" />
      <div
        class="drawer-btn"
        v-if="user_info.permission == 1"
        @click="clearTeam"
      >
        全选
      </div>
    </Drawer>
    <webUpload></webUpload>
    <independentLive></independentLive>
  </div>
</template>

<script>
import { upfile, editUserPass, getUserInfo } from '@/utils/javaApi.js'
import TeamListCascader from '@/components/statistics/commonComponents/TeamListCascader.vue'
import TeamTreeCascader from '@/components/team/teamTreeCascader.vue'
import Incident from '@/components/incident.vue'
import independentLive from '@/components/independentLive.vue'
import webUpload from '@/components/webupload.vue'
import Api from '../utils/api.js'

import phone from '@/assets/img/statistics/phone.png'
import phoneOff from '@/assets/img/statistics/phoneOff.png'
import phoneOn from '@/assets/img/statistics/phoneOn.png'
import callOff from '@/assets/img/statistics/callOff.png'
import callOn from '@/assets/img/statistics/callOn.png'
// import Socket from '@/utils/socket.mixin.js';
import Recorder from 'js-audio-recorder'
import TIMUploadPlugin from 'tim-upload-plugin'
export default {
  name: 'layout',

  //   mixins: [Socket],
  components: {
    TeamListCascader,
    Incident,
    TeamTreeCascader,
    webUpload,
    independentLive,
  },

  data: function () {
    return {
      myicon: {
        callOn: callOn,
        callOff: callOff,
        phone: phone,
        phoneOn: phoneOn,
        phoneOff: phoneOff,
      },
      inviteData: {},
      inviteID: null,
      inviterName: '',
      userName: '',
      Logo: require('@/assets/fulin/fulin-logo.png'),
      UserData: {},
      nav: '',
      navDataL:{
        sys_icon:'',
        sys_name:''
      },
      teamShow: true,
   /*   teamValue: false,*/
      showIncident: false, // 显示事件面板
      isRouteRefresh: true,
      incidentName: '',
      drawerStyle: {
        overflow: 'auto',
        paddingBottom: '53px',
        position: 'static',
        // background: '#1f1f1f',
      },
      MenuList: [
        {
          name: '指挥',
          img: require('@/assets/img/Icon/sidebar_live.png'),
          img_light: require('@/assets/img/Icon/sidebar_live_light.png'),
          img_press: require('@/assets/img/Icon/sidebar_live_press.png'),
          img_press_light: require('@/assets/img/Icon/sidebar_live_press_light.png'),
          path: '/live/watch-lists',
          includes: [
            {
              path: '/live/live-lists',
              name: '实时直播',
            },
          ],
        },
        {
          name: '航线',
          img: require('@/assets/img/Icon/sidebar_project.png'),
          img_light: require('@/assets/img/Icon/sidebar_project_light.png'),
          img_press: require('@/assets/img/Icon/sidebar_project_press.png'),
          img_press_light: require('@/assets/img/Icon/sidebar_project_press_light.png'),
          path: '/index/project',
          includes: [
            {
              name: '航线',
              path: '/route-specification',
            },
            {
              name: '创建航线任务',
              path: '/create/air-line/application',
            },
            {
              name: '创建三维航线任务',
              path: '/create/air-line/3D-line',
            },
            {
              name: '二级工程列表',
              path: '/index/project/ProjectBar_Level_2',
            },
            {
              name: '任务详情',
              path: '/index/PT/TaskBar',
            },
          ],
        },
        {
          name: '任务',
          img: require('@/assets/img/Icon/sidebar_task.png'),
          img_light: require('@/assets/img/Icon/sidebar_task_light.png'),
          img_press: require('@/assets/img/Icon/sidebar_task_press.png'),
          img_press_light: require('@/assets/img/Icon/sidebar_task_press_light.png'),
          path: '/index/task',
          includes: [
            {
              name: '任务管理',
              path: '/index/task/task',
            },
            {
              name: '审核管理',
              path: '/index/task/examine',
            },
            {
              name: '设置虚拟护栏',
              path: '/index/task/noFlyZone',
            },
          ],
        },
        {
          name: '统计',
          img: require('@/assets/img/Icon/sidebar_sum.png'),
          img_light: require('@/assets/img/Icon/sidebar_sum_light.png'),
          img_press: require('@/assets/img/Icon/sidebar_sum_press.png'),
          img_press_light: require('@/assets/img/Icon/sidebar_sum_press_light.png'),
          path: '/statistics/index',
        },
        {
          name: '记录',
          img: require('@/assets/img/Icon/sidebar_tra.png'),
          img_light: require('@/assets/img/Icon/sidebar_tra_light.png'),
          img_press: require('@/assets/img/Icon/sidebar_tra_press.png'),
          img_press_light: require('@/assets/img/Icon/sidebar_tra_press_light.png'),
          path: '/statistics/records',
          includes: [
            {
              name: '轨迹回放',
              path: '/statistics/trajectory-playback',
            },
            {
              name: '执法记录仪回放',
              path: '/statistics/LawPlay',
            },
          ],
        },
        {
          name: '设备',
          img: require('@/assets/img/Icon/sidebar_device.png'),
          img_light: require('@/assets/img/Icon/sidebar_device_light.png'),
          img_press: require('@/assets/img/Icon/sidebar_device_press.png'),
          img_press_light: require('@/assets/img/Icon/sidebar_device_press_light.png'),
          path: '/index/equipment',
          includes: [
            {
              name: '无人机管理',
              path: '/index/equipment/UavManage',
            },
            {
              name: '电池管理',
              path: '/index/equipment/BatteryManage',
            },
            {
              name: '使用记录',
              path: '/index/equipment/UsageLog',
            },
            {
              name: '设备保养',
              path: '/index/equipment/EquipmentMaintenance',
            },
            {
              name: '警用适航证',
              path: '/index/equipment/table',
              includes: [
                { path: '/index/equipment/table/tableOne' },
                { path: '/index/equipment/table/tableTwo' },
                { path: '/index/equipment/table/tableThree' },
                { path: '/index/equipment/table/tableFour' },
                { path: '/index/equipment/table/tableFive' },
                { path: '/index/equipment/table/tableSix' },
              ],
            },
          ],
        },
        {
          name: '人员',
          img: require('@/assets/img/Icon/sidebar_team.png'),
          img_light: require('@/assets/img/Icon/sidebar_team_light.png'),
          img_press: require('@/assets/img/Icon/sidebar_team_press.png'),
          img_press_light: require('@/assets/img/Icon/sidebar_team_press_light.png'),
          path: '/index/team',
          includes: [
            {
              name: '团队',
              path: '/index/team',
            },
          ],
        },
        {
          name: '成果',
          img: require('@/assets/img/Icon/sidebar_model.png'),
          img_light: require('@/assets/img/Icon/sidebar_model_light.png'),
          img_press: require('@/assets/img/Icon/sidebar_model_press.png'),
          img_press_light: require('@/assets/img/Icon/sidebar_model_press_light.png'),
          path: '/index/resource/modal',
          includes: [
            {
              name: '全部',
              img: require('@/assets/img/Icon/sidebar_overview.png'),
              img_press: require('@/assets/img/Icon/sidebar_overview_press.png'),
              path: '/overview',
            },
            {
              name: '沙盘模型',
              path: '/index/resource',
              includes: [
                {
                  name: 'Terra首页',
                  path: '/resourceIndex/Terra',
                },
                {
                  name: '云计算选择绑定建图任务',
                  path: '/resourceIndex/establish',
                },
                {
                  name: '云计算上传页',
                  path: '/resourceIndex/uploadingPage',
                },

                // {
                //   name: '正射查看',
                //   path: '/resourceIndex/orthographicShou',
                // },
                {
                  name: '模型查看',
                  path: '/model/List',
                },
                {
                  name: '正射查看',
                  path: '/orthographicShou',
                },
              ],
            },
            {
              name: '二维地图',
              path: '/index/resource/ermodal',
              //   includes: [
              //     {
              //       name: '正射查看',
              //       path: '/resourceIndex/orthographicShou',
              //     },
              //   ],
            },
            {
              name: '快速拼图',
              path: '/index/resource/rapidMap',
              includes: [
                // { name: '快速对比', path: '/rapidMap/contrast' },
                { name: '快拼标注', path: '/rapidMap/label' },
                // {
                //   name: '保存截图',
                //   path: '/rapidMap/screenshot',
                // },
              ],
            },
            {
              name: '全景展示',
              path: '/index/resource/panorama',
              includes: [
                { name: '全景图编辑', path: '/index/edtiPanorama' },
                { name: '全景图详情', path: '/index/detailPanorama' },
                { name: '全景图管理', path: '/index/mgrPanorama' },
              ],
            },
            // 视频文件自由上传
            {
              name: '媒体资源',
              path: '/index/resource/media',
            },
          ],
        },
      /*  {
          name: '上云',
          img: require('@/assets/img/shangyun/shangyun.png'),
          img_press: require('@/assets/img/shangyun/shangyun.png'),
          path: '/shangyun/index',
          children: [
            {
              name: '实时监控',
              path: '/shangyun/live/watch-lists',
            },
            {
              name: '无人机直播',
              path: '/shangyun/live/live-lists',
            },
            {
              name: '航线',
              path: '/shangyun/lane/index',
            },
            {
              name: '资源',
              path: '/shangyun/resource/media',
            },
          ],
        },*/
      ],
      changeRed: 0,
      activeChildMenu: 0,
      user_info: {}, //用户数据
      timer: null,
      websockid: 0,
      websock: null,
      isbind: false,
      speakState: '', //远程喊话状态
      chatMsgLists: {
        //聊天
        list: [],
        record: [],
      },
      controlState: '', //远程控制状态
      closeWebsocket: false, //是否关闭Websocket
      userType: '',
      EditUserShou: false,
      EditUser: {
        head_icon: '',
        name: '',
        OldPassword: '',
        NewPassword: '',
        checkNewPassword: '',
        phone: '',
        smscode: '',
      },
      uploadList: [],
      changePasswordType: 0,

      ruleValidate: {
        name: [
          {
            required: true,
            message: '用户名不能为空',
            trigger: 'blur',
          },
          {
            message: '用户名长度需为2-15位',
            trigger: 'blur',
            min: 2,
            max: 15,
          },
        ],
      },
      unboundModal: false,
      bindModal: false,
      reckon_time: 60,
      userPhone: '',
      reckon_time_shou: false,
      reckon_time_ent: true,
      uploading: false,
      client: null,
      uplinkClient: null, // 用于检测上行网络质量
      downlinkClient: null, // 用于检测下行网络质量
      localStream: null, // 用于测试的流
      testResult: {
        // 记录上行网络质量数据
        uplinkNetworkQualities: [],
        // 记录下行网络质量数据
        downlinkNetworkQualities: [],
        average: {
          uplinkNetworkQuality: 0,
          downlinkNetworkQuality: 0,
        },
      },
      recordOptions: {
        hadPermission: false,
        isUploading: false,
        hadToast: false,
      },
    }
  },
  computed:{
    teamValue:{
      get(){
        return this.$store.state.teamValue
      },
      set(v) {
        this.teamValues = v
      }
    }
  },
  watch: {
    $route(val) {
      this.findActiveTab()
      // console.log(
      //   val.name,
      //   val.name === "任务管理",
      //   val.name === "全景列表",
      //   val.name === "团队",
      //   val.name === "审核管理"
      // );
      if (
        val.name === '全景列表' ||
        val.name === '团队' ||
        val.name === '审核管理'
      ) {
        this.teamShow = false
      } else {
        this.teamShow = true
        // if (
        //   val.name === "监控列表" ||
        //   val.name === "实时直播" ||
        //   val.name === "无人机管理" ||
        //   val.name === "电池管理" ||
        //   val.name === "设备保养"
        // ) {
        //   this.hasEid = false;
        // } else {
        //   if (sessionStorage.getItem("Eid")) {
        //     this.hasEid = true;
        //   }
        // }
      }
      console.log('route', val.name)
    },
    // '$store.state.darkTheme'(newval) {
    //   if (newval) {
    //     this.drawerStyle.background = '#1f1f1f';
    //   } else {
    //     this.drawerStyle.background = '#f0f0f0';
    //   }
    // },
  },

  methods: {
    JilinProvinceBut(){
      this.$router.push('/JilinProvince')
      var de = document.documentElement;
      if (de.requestFullscreen) {
        de.requestFullscreen();
      } else if (de.mozRequestFullScreen) {
        de.mozRequestFullScreen();
      } else if (de.webkitRequestFullScreen) {
        de.webkitRequestFullScreen();
      } else if (de.msRequestFullscreen) {
        de.msRequestFullscreen();
      }
    },
    teamValueCloseBut(){
      this.$store.commit('setTeamValue',false)
    },
    // 主题色切换
    theme(type) {
      if (type === 0 && !this.$store.state.darkTheme) {
        this.$store.commit('changeTheme', true)
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--hui6', '#666')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--huiC', '#ccc')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--hui3', '#333')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--white', '#fff')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--black', '#000')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--xf-hui1', '#1F1F1F')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--xf-hui2', '#3C3D43')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--xf-hui2-2', '#3C3D43')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--xf-hui3', '#777777')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--xf-hui4', '#232426')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--xf-hui4-hover', '#56585e')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--xf-hui5', '#313131')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--xf-hui5-hover', '#5d5d5d')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--xf-hui6', '#454545')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--xf-hui7', '#4A4A4A')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--xf-hui7-hover', '#252525')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--xf-hei1', '#383838')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--xf-hei1-2', '#383838')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--xf-hei2', '#191b1c')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--xf-hei3', '#1c1c1c')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--xf-hei4', '#2b2b2c')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--xf-hei5', '#28292e')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--xf-hei6', '#2c2c2c')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--xf-lan1', '#4E77A4')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--font-color-1', '#C9CACA')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--ivu-table-td-1', '#1e1e1e')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--ivu-table-td-2', '#1d1d1d')
      } else if (type === 1 && this.$store.state.darkTheme) {
        this.$store.commit('changeTheme', false)
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--hui6', '#9f9f9f')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--huiC', '#3f3f3f')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--hui3', '#f8f9fb')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--white', '#0f0f0f')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--black', '#d9d9d9')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--xf-hui1', '#f3f4f5')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--xf-hui2', '#f5f5f5')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--xf-hui2-2', '#d6d6d6')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--xf-hui3', '#8f8f8f')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--xf-hui4', '#fefbff')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--xf-hui4-hover', '#a9a7a2')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--xf-hui5', '#fff')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--xf-hui5-hover', '#e4e4e4')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--xf-hui6', '#bababa')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--xf-hui7', '#f5f5f5')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--xf-hui7-hover', '#dadada')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--xf-hei1', '#fff')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--xf-hei1-2', '#0774ca')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--xf-hei2', '#f5f5f5')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--xf-hei3', '#e3e3e3')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--xf-hei4', '#d4d4d3')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--xf-hei5', '#f1f1f1')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--xf-hei6', '#d2d2d2')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--xf-lan1', '#b1885b')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--font-color-1', '#363635')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--ivu-table-td-1', '#fff')
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--ivu-table-td-2', '#fcfcfc')
      }
      console.log(this.$store.state.darkTheme)
    },
    // 语音通话----->>>>
    // 通话监听
    getNewInvitationDialogContent: function () {
      return `来自${this.inviterName}的语音通话`
    },
    handleError(err) {
     // console.log(err)
    /*  this.$notice.error({
        title: '系统提示',
        desc: '无法接通电话，请检查您的麦克风。',
      })*/
    },
    handleInviteeINVITED(payload) {
      console.log('接到电话')
      if (!this.$store.state.isCall) {
        this.$notice.error({
          title: '系统提示',
          desc: '对方线忙，请稍后重试',
        })
        return
      }
      const { inviteID, sponsor, inviteData } = payload
      let a = sponsor
      this.inviterName = a
      this.$store.state.isShowNewInvitationDialog = true
      this.inviteID = inviteID
      this.inviteData = inviteData
    },
    handleUserEnter({ userID }) {
      console.log('用户进房')
      this.$store.state.isCall = true
      this.$store.state.showVideo = true
      this.$store.state.isShowNewInvitationDialog = false
      // this.$trtcCalling.startRemoteView({userID:userID.sponsor, videoViewDomID:`video-${userID.sponsor}`})
      // this.$trtcCalling.startLocalView({userID:this.userName, videoViewDomID:`video-${this.userName}`})
    },
    handleInviteeReject(invite) {
      this.$store.state.isCall = true
      this.$notice.error({
        title: '对方拒绝通话',
        // desc: `${error.name} : ${error.message}`,
      })
    },
    handleNoResponse(invite) {
      this.$store.state.isCall = true
      this.$store.state.isShowNewInvitationDialog = false
      this.$notice.error({
        title: '通话超时未应答',
        // desc: `${error.name} : ${error.message}`,
      })
    },
    handleCallingTimeout() {
      this.$store.state.isCall = true
      this.$store.state.isShowNewInvitationDialog = false
      this.$notice.error({
        title: '通话超时未应答',
        // desc: `${error.name} : ${error.message}`,
      })
    },
    handleLineBusy(invite) {
      console.log('asdsadsad====', invite)
      this.$store.state.isCall = true
      this.$notice.error({
        title: '系统提示',
        desc: `用户${invite.userID}线忙`,
      })
    },
    handleCallingEnd() {
      this.$store.state.showVideo = false
      this.$notice.success({
        title: '通话结束',
        // desc: `${error.name} : ${error.message}`,
      })
    },

    handleCallingCancel() {
      this.$store.state.isCall = true
      this.$store.state.isShowNewInvitationDialog = false
      this.$notice.error({
        title: '通话取消',
        // desc: `${error.name} : ${error.message}`,
      })
    },

    ok() {
      Recorder.getPermission().then(
        () => {
          console.log('给权限了')
          const { callType, roomID } = this.inviteData
          this.$trtcCalling.accept({
            inviteID: this.inviteID, //邀请 ID, 标识一次邀请
            roomID, //通话房间号 ID
            callType, //0-未知， 1-语音通话，2-视频通话
          })
          this.$store.state.isCall = false
        },
        (error) => {
          this.$notice.error({
            title: '系统提示！',
            desc: '获取麦克风失败',
          })
          this.recordOptions.hadPermission = true
          this.$store.state.isCall = true
        }
      )
    },
    cancel() {
      const { callType } = this.inviteData
      this.$store.state.isShowNewInvitationDialog = false
      this.$trtcCalling.reject({
        inviteID: this.inviteID,
        isBusy: false,
        callType,
      })
      this.$store.state.isCall = true
    },
    hang() {
      this.$trtcCalling.hangup()
      this.$store.state.isCall = true
    },
    // <<<<<-----语音通话

    goPage(item) {
      sessionStorage.setItem('page', 1) // 设置初始翻页
      if (this.$route.path.indexOf(item.path) !== -1) {
        return
      }
      this.$router.push(item.path)
    },
    // 子路由设置聚焦样式
    setActiveMenu(index, item) {
      this.activeChildMenu = parseInt(index)
      this.goPage(item)
    },
    // 根据路由直接定位到tab
    findActiveTab() {
      let activePath = this.$route.path
      let findIndex = (arr, key, target) => {
        for (let i = 0, L = arr.length; i < L; i++) {
          if (arr[i][key] === target) {
            return i
          } else {
            if (arr[i].children) {
              let ret = findIndex(arr[i].children, key, target)
              if (ret !== -1) {
                this.activeChildMenu = ret
                return i
              }
            }
            if (arr[i].includes) {
              let includesRet = findIndex(arr[i].includes, key, target)
              if (includesRet !== -1) {
                this.activeChildMenu = i //3
                return i
              }
            }
          }
        }
        this.activeChildMenu = 0
        return -1
      }
      let ret = findIndex(this.MenuList, 'path', activePath)
      this.changeRed = ret === -1 ? 0 : ret
    },
    // 退出登录
    exit() {
      this.$Modal.confirm({
        title: '退出系统',
        content: '您确定要退出该系统吗？',
        onOk: () => {
          this.$get('/logout', {}).then((res) => {
            localStorage.clear()
            sessionStorage.clear()
            this.$_bus.$emit('closeSocket', true) //关闭Websocket
            if (res.code === 1) {
              this.$Notice.success({
                title: '退出',
                desc: '退出成功',
              })
              this.$router.push({
                path: '/',
              })
            } else {
              this.$Message.error(res.msg_customer)
              this.$router.push({
                path: '/',
              })
            }
          })
          this.trteLogout()
        },
        onCancel: () => {},
      })
    },
    // 切换导航高亮区
    MenuSwitchover(item, ind) {
      console.log(ind)
      this.activeChildMenu = 0
      this.changeRed = ind
      this.goPage(item)
    },
    // 展开团队选择
    showDrawer() {
      if (this.teamShow) {
        this.$store.commit('setTeamValue',true)
      }
    },
    // 清除团队选择
    clearTeam() {
      this.$refs.teamTree.clearUp()
    },
    selfTeam() {
      this.$refs.teamTree.self()
    },
    // getData() {
    //   //绑定websocket
    //   this.$post('/base/common/bindClient', {
    //     client: 'web',
    //     client_id: this.websockid,
    //   }).then((res) => {
    //     if (res.code == 1) {
    //       this.isbind = true;
    //     } else {
    //       this.isbind = false;
    //     }
    //   });
    // },
    // 新增用户的头像上传
    handleFormatError() {
      this.$Notice.warning({
        title: '文件格式不正确',
        desc: '文件格式请选择jpg或png.',
      })
    },
    handleMaxSize() {
      this.$Notice.warning({
        title: '超过限定大小限制',
        desc: '上传的图片大小不得大于2M.',
      })
    },
    // 重置此头像
    handleRemove(item) {
      this.uploadList.splice(this.uploadList.indexOf(item), 1)
    },
    // 编辑弹框的头像上传=====================
    //上传用户头像
    uploadBefore_Edit(file) {
      if (file) {
        if (file.size > 2048000) {
          this.$Notice.warning({
            title: '超过限定大小限制',
            desc: '上传的图片大小不得大于2M.',
          })
          return false
        }
        if (
          file.type == 'image/jpg' ||
          file.type == 'image/png' ||
          file.type == 'image/jpeg'
        ) {
          const url = upfile()
          let fm = new FormData()
          fm.append('client', 'web')
          fm.append('file', file)
          fm.append('teamId', this.user_info.team_id)
          this.$fromdata(url, fm).then((res) => {
            if (res.code == 1) {
              this.EditUser.head_icon = res.data.path + res.data.head_icon
              this.uploadList.push({
                status: 'finished',
                url: res.data.head_icon,
                path: res.data.path,
              })
            } else {
              this.$Message.error(res.msg_customer)
            }
          })
        } else {
          this.$Notice.warning({
            title: '文件格式不正确',
            desc: '文件格式请选择jpg或png.',
          })
          return false
        }
      }
    },
    // 重置此头像
    handleRemove_Edit(item) {
      console.log('点击了删除')
      this.EditUser.head_icon = ''
      this.uploadList.splice(this.uploadList.indexOf(item), 1)
    },
    // 切换是否修改密码
    isChangePassword() {
      if (this.changePasswordType) {
        this.ruleValidate = {
          name: [
            {
              required: true,
              message: '用户名不能为空',
              trigger: 'blur',
            },
            {
              message: '用户名长度需为2-15位',
              trigger: 'blur',
              min: 2,
              max: 15,
            },
          ],
          OldPassword: [
            {
              required: true,
              message: '原密码不能为空',
              trigger: 'blur',
            },
            {
              message: '密码长度需为6-16位',
              trigger: 'blur',
              min: 6,
              max: 16,
            },
          ],
          NewPassword: [
            {
              required: true,
              message: '新密码不能为空',
              trigger: 'blur',
            },
            {
              type: 'string',
              min: 8,
              max: 20,
              pattern: /^[0-9a-zA-Z]+$/,
              message: '请使用长度为8-20个字符的字母与数字',
            },
          ],
          checkNewPassword: [
            {
              required: true,
              message: '验证密码不能为空',
              trigger: 'blur',
            },
            {
              type: 'string',
              min: 8,
              max: 20,
              pattern: /^[0-9a-zA-Z]+$/,
              message: '请使用长度为8-20个字符的字母与数字',
            },
          ],
        }
      } else {
        this.ruleValidate = {
          name: [
            {
              required: true,
              message: '用户名不能为空',
              trigger: 'blur',
            },
            {
              message: '用户名长度需为2-15位',
              trigger: 'blur',
              min: 2,
              max: 15,
            },
          ],
        }
        this.EditUser.OldPassword = ''
        this.EditUser.NewPassword = ''
        this.EditUser.checkNewPassword = ''
      }
    },
    // 提交编辑用户
    EditUser_ok() {
      let data = {
        client: 'web',
        name: this.EditUser.name,
        user_id: Number(this.user_info.user_id),
        head_icon: this.uploadList.length === 0 ? null : this.uploadList[0].url,
        isModifyPasswd: this.changePasswordType,
      }
      if (data.isModifyPasswd == 1) {
        data.old_passwd = this.EditUser.OldPassword
        data.new_passwd = this.EditUser.NewPassword
        data.check_pass = this.EditUser.checkNewPassword
        if (!data.old_passwd || !data.new_passwd || !data.check_pass) {
          this.$Message.error('密码未输入')
          return
        }
        if (data.new_passwd !== data.check_pass) {
          this.$Message.error('验证密码必须与新密码一致')
          return
        }
        data.old_passwd = this.$publicdata.encrypt(this.EditUser.OldPassword)
        data.new_passwd = this.$publicdata.encrypt(this.EditUser.NewPassword)
        data.check_pass = this.$publicdata.encrypt(
          this.EditUser.checkNewPassword
        )
      }
      this.$refs['EditUser'].validate((valid) => {
        if (valid) {
          this.$post(editUserPass(), data)
            .then((res) => {
              if (res.code == 1) {
                this.$Message.info('修改成功')
                this.getUserData()
                this.EditUserShou = false
                this.uploadList = []
                this.EditUser.head_icon = ''
                this.OldPassword = ''
                this.NewPassword = ''
                this.checkNewPassword = ''
                this.$refs['EditUser'].resetFields()
              } else {
                this.$Message.error(res.msg_customer)
              }
            })
            .catch((err) => {
              //   this.uploadList.splice(this.uploadList.indexOf(item), 1);
              this.uploadList = []

              this.EditUser.head_icon = ''
            })
        }
      })
    },
    // 用户编辑弹窗关闭时
    closeEditUser() {
      JSON.parse(sessionStorage.getItem('user_info')).user_id
      this.$refs['EditUser'].resetFields()
      this.EditUser.name = this.UserData.name
      this.EditUser.head_icon = this.UserData.head_icon
      this.uploadList = []
      this.EditUser.OldPassword = ''
      this.EditUser.NewPassword = ''
    },
    // 获取登录用户信息
    getUserData() {
      this.$post(getUserInfo()).then((res) => {
        if (res.code == 1) {
          this.UserData = res.data
          this.EditUser.name = this.UserData.name
          this.EditUser.head_icon = this.UserData.head_icon
          console.log(this.EditUser.head_icon)
          sessionStorage.setItem('user_info', JSON.stringify(res.data))
          sessionStorage.setItem('userPhone', res.data.mobilePhone)
        } else {
          this.isbind = false
        }
      })
    },
    // 显示事件栏
    IncidentShow() {
      this.showIncident = true
    },
    // 隐藏事件栏
    IncidentHide() {
      this.showIncident = false
      if (sessionStorage.getItem('Eid')) {
        // this.hasEid = true;
        this.incidentName = sessionStorage.getItem('Ename')
      } else {
        // this.hasEid = false;
        this.incidentName = ''
      }
      this.isRouteRefresh = false
      this.$nextTick(() => {
        this.isRouteRefresh = true
      })
      // if (
      //   this.$route.name === "监控列表" ||
      //   this.$route.name === "实时直播" ||
      //   this.$route.name === "电池管理" ||
      //   this.$route.name === "设备保养" ||
      //   this.$route.name === "无人机管理"
      // ) {
      //   this.hasEid = false;
      // }
    },

    // 发送验证码
    sendSecurity() {
      if (this.reckon_time_shou && this.reckon_time_ent) {
        console.log('time_end', this.reckon_time_shou)
        console.log('time_end', this.reckon_time_ent)
        return
      }
      if (this.EditUser.phone == '') {
        this.$Message.error('需填写手机号码')
        return false
      }
      this.$post(Api.sendCloudSms(), {
        phone: this.EditUser.phone,
      }).then((res) => {
        console.log(res)
        if (res.code == 1) {
          if (res.msg_customer == '此号码已被注册') {
            this.$Message.info(res.msg_customer)
            return
          }
          this.$Message.info(res.msg_customer)
          this.reckon_time_shou = true
          this.reckon_time_ent = true
          this.reckon_time_timer = setInterval(() => {
            if (this.reckon_time == 0) {
              clearInterval(this.reckon_time_timer)
              this.reckon_time = 60
              this.EditUser.smscode = ''
              this.reckon_time_ent = false
            }
            this.reckon_time = this.reckon_time - 1
          }, 1000)
        } else {
          this.$Message.error(res.msg_customer)
        }
      })
    },

    // 解除手机号绑定
    okUnbound() {
      /**
       * 1.验证码校验
       * 2.用户信息修改
       */
      this.$post(Api.checkSms(), {
        phone: this.userPhone,
        code: this.EditUser.smscode,
      }).then((res) => {
        if (res.code == 1) {
          this.$post(Api.selfEdit(), {
            mobile_phone: '',
            user_id: this.user_info.user_id,
          }).then((response) => {
            if (response.code == 1) {
              this.$Message.info(response.msg_customer)
              this.unboundModal = false
              sessionStorage.setItem('userPhone', '')
              this.userPhone = ''
              this.EditUser.smscode = ''
              this.EditUser.phone = ''
            } else {
              this.$Message.error(response.msg_customer)
            }
          })
        } else {
          this.$Message.error(res.msg_customer)
        }
      })
    },
    cancelUnbound() {},
    // 绑定手机号
    okbind() {
      /**
       * 1.验证码校验
       * 2.用户信息修改
       */
      this.$post(Api.checkSms(), {
        phone: this.EditUser.phone,
        code: this.EditUser.smscode,
      }).then((res) => {
        if (res.code == 1) {
          this.$post(Api.selfEdit(), {
            mobile_phone: this.EditUser.phone,
            user_id: this.user_info.user_id,
          }).then((response) => {
            if (response.code == 1) {
              this.$Message.info(response.msg_customer)
              this.bindModal = false
              this.EditUser.smscode = ''
              this.userPhone = this.EditUser.phone
              sessionStorage.setItem('userPhone', this.EditUser.phone)
            } else {
              this.$Message.error(response.msg_customer)
            }
          })
        } else {
          this.$Message.error(res.msg_customer)
        }
      })
    },
    cancelbind() {},
    //TRTCCalling 登录
    trtcLogin() {
      this.userName = JSON.parse(
        sessionStorage.getItem('user_info')
      ).user_id.toString()
      let userInfo = JSON.parse(sessionStorage.getItem('user_info'))
      this.$post(Api.getUserSig(), {
        user_id: userInfo.user_id,
      }).then((res) => {
        if (res.code == 1) {
          this.userSig = res.data.userSig
          this.$trtcCalling
            .login({
              userID: this.userName,
              userSig: this.userSig,
            })
            .then((res) => {
              console.log('登录成功', res)
            })
            .catch((error) => {
              console.log('登录失败', error)
            })
        } else {
          this.$Message.error(res.msg_customer)
        }
      })
    },

    trteLogout() {
      //TRTCCalling 登出
      this.$trtcCalling
        .logout({
          userID: this.userName,
          userSig: this.userSig,
        })
        .then((res) => {
          console.log('退出成功', res)
        })
        .catch((error) => {
          console.log('退出失败', error)
        })
    },
    //添加监听
    initListener() {
      // 语音通话----->>>>>
      //监听通话
      // this.$trtcCalling.on('onInvited', this.handleInvite, this)
      this.$trtcCalling.on(this.TrtcCalling.EVENT.ERROR, this.handleError)
      this.$trtcCalling.on(
        this.TrtcCalling.EVENT.INVITED,
        this.handleInviteeINVITED
      )
      this.$trtcCalling.on(
        this.TrtcCalling.EVENT.USER_ENTER,
        this.handleUserEnter
      )
      this.$trtcCalling.on(
        this.TrtcCalling.EVENT.REJECT,
        this.handleInviteeReject
      )
      this.$trtcCalling.on(
        this.TrtcCalling.EVENT.NO_RESP,
        this.handleNoResponse
      )
      this.$trtcCalling.on(
        this.TrtcCalling.EVENT.LINE_BUSY,
        this.handleLineBusy
      )
      this.$trtcCalling.on(
        this.TrtcCalling.EVENT.CALL_END,
        this.handleCallingEnd
      )
      this.$trtcCalling.on(
        this.TrtcCalling.EVENT.CALLING_TIMEOUT,
        this.handleCallingTimeout
      )
      this.$trtcCalling.on(
        this.TrtcCalling.EVENT.CALLING_CANCEL,
        this.handleCallingCancel
      )
      // <<<<<-----语音通话
    },
    //删除监听
    removeListener() {
      // 语音通话>>>>>
      //监听通话
      // this.$trtcCalling.off('onInvited', this.handleInvite, this);
      this.$trtcCalling.off(this.TrtcCalling.EVENT.ERROR, this.handleError)
      this.$trtcCalling.off(
        this.TrtcCalling.EVENT.INVITED,
        this.handleInviteeINVITED
      )
      this.$trtcCalling.off(
        this.TrtcCalling.EVENT.USER_ENTER,
        this.handleUserEnter
      )
      this.$trtcCalling.off(
        this.TrtcCalling.EVENT.REJECT,
        this.handleInviteeReject
      )
      this.$trtcCalling.off(
        this.TrtcCalling.EVENT.NO_RESP,
        this.handleNoResponse
      )
      this.$trtcCalling.off(
        this.TrtcCalling.EVENT.LINE_BUSY,
        this.handleLineBusy
      )
      this.$trtcCalling.off(
        this.TrtcCalling.EVENT.CALL_END,
        this.handleCallingEnd
      )
      this.$trtcCalling.off(
        this.TrtcCalling.EVENT.CALLING_TIMEOUT,
        this.handleCallingTimeout
      )
      this.$trtcCalling.off(
        this.TrtcCalling.EVENT.CALLING_CANCEL,
        this.handleCallingCancel
      )
      // <<<<<语音通话
    },

    // 获取头部名称
    /*getTeamEtCustom(){
      let date={
        team_id:this.user_info.team_id
      }
      this.$filePost(Api.teamEtCustom(),date).then(res=>{
        this.navDataL.sys_icon=(res.data.sys_icon!==undefined&&res.data.sys_icon!==null)?res.data.sys_icon:''
        this.navDataL.sys_name=(res.data.sys_name!==undefined&&res.data.sys_name!==null)?res.data.sys_name:''
      })
    }*/
  },

  created() {
    if (this.$public) {
      this.initListener()
    }

    // if (this.$CSTJ) {
    //   this.Logo = require('@/assets/img/Logo_police.png'); // 长沙警航logo
    //   //require('@/assets/img/ZhuZhouJinHangLogo.png'); // 株洲警航logo
    // } else if (this.$CSXF) {
    //   this.theme(1);
    //   this.IncidentShow();
    //   this.Logo = require('@/assets/img/Logo_fire.png'); // 长沙消防logo
    // }
    if (this.$CSXF) {
      this.theme(1)
      this.IncidentShow()
    }
  },

  mounted() {
    if (this.$public) {
      this.trtcLogin()
    }

    //获取麦克风
    // TRTC.getMicrophones()
    //   .then((microphoneList) => {
    //     microphoneList.forEach((item) => {
    //       console.log(
    //         'microphone: ' + item.kind + ' ' + item.label + ' ' + item.deviceId
    //       )
    //     })
    //   })
    //   .catch((error) => console.error('getMicrophones error observed ' + error))

    // 验证用户是否登录
    this.user_info = this.$publicdata.CheckuserLogin()
    this.userType = sessionStorage.getItem('userType')
    this.UserData = this.user_info
    this.userPhone = sessionStorage.getItem('userPhone')
    // 飞手路由
    if (this.userType == 2) {
      this.MenuList = [
        {
          name: '指挥',
          img: require('@/assets/img/Icon/sidebar_live.png'),
          img_light: require('@/assets/img/Icon/sidebar_live_light.png'),
          img_press: require('@/assets/img/Icon/sidebar_live_press.png'),
          img_press_light: require('@/assets/img/Icon/sidebar_live_press_light.png'),
          path: '/live/watch-lists',
          includes: [
            {
              path: '/live/live-lists',
              name: '实时直播',
            },
          ],
        },
        {
          name: '航线',
          img: require('@/assets/img/Icon/sidebar_project.png'),
          img_light: require('@/assets/img/Icon/sidebar_project_light.png'),
          img_press: require('@/assets/img/Icon/sidebar_project_press.png'),
          img_press_light: require('@/assets/img/Icon/sidebar_project_press_light.png'),
          path: '/index/project',
          includes: [
            {
              name: '航线',
              path: '/route-specification',
            },
            {
              name: '创建航线任务',
              path: '/create/air-line/application',
            },
            {
              name: '创建三维航线任务',
              path: '/create/air-line/3D-line',
            },
            {
              name: '二级工程列表',
              path: '/index/project/ProjectBar_Level_2',
            },
            {
              name: '任务详情',
              path: '/index/PT/TaskBar',
            },
          ],
        },
        {
          name: '任务',
          img: require('@/assets/img/Icon/sidebar_task.png'),
          img_light: require('@/assets/img/Icon/sidebar_task_light.png'),
          img_press: require('@/assets/img/Icon/sidebar_task_press.png'),
          img_press_light: require('@/assets/img/Icon/sidebar_task_press_light.png'),
          path: '/index/task',
          includes: [
            {
              name: '任务管理',
              path: '/index/task/task',
            },
            {
              name: '审核管理',
              path: '/index/task/examine',
            },
            {
              name: '设置虚拟护栏',
              path: '/index/task/noFlyZone',
            },
          ],
        },
        {
          name: '统计',
          img: require('@/assets/img/Icon/sidebar_sum.png'),
          img_light: require('@/assets/img/Icon/sidebar_sum_light.png'),
          img_press: require('@/assets/img/Icon/sidebar_sum_press.png'),
          img_press_light: require('@/assets/img/Icon/sidebar_sum_press_light.png'),
          path: '/statistics/index',
        },
        {
          name: '记录',
          path: '/statistics/records',
          img: require('@/assets/img/Icon/sidebar_tra.png'),
          img_light: require('@/assets/img/Icon/sidebar_tra_light.png'),
          img_press: require('@/assets/img/Icon/sidebar_tra_press.png'),
          img_press_light: require('@/assets/img/Icon/sidebar_tra_press_light.png'),
          includes: [
            {
              name: '轨迹回放',
              path: '/statistics/trajectory-playback',
            },
            {
              name: '执法记录仪回放',
              path: '/statistics/LawPlay',
            },
          ],
        },
        {
          name: '设备',
          img: require('@/assets/img/Icon/sidebar_device.png'),
          img_light: require('@/assets/img/Icon/sidebar_device_light.png'),
          img_press: require('@/assets/img/Icon/sidebar_device_press.png'),
          img_press_light: require('@/assets/img/Icon/sidebar_device_press_light.png'),
          path: '/index/equipment',
          includes: [
            {
              name: '无人机管理',
              path: '/index/equipment/UavManage',
            },
            // {
            //   name: '电池管理',
            //   path: '/index/equipment/BatteryManage',
            // },
            // {
            //   name: '使用记录',
            //   path: '/index/equipment/UsageLog',
            // },
            // {
            //   name: '设备保养',
            //   path: '/index/equipment/EquipmentMaintenance',
            // },
            // {
            //   name: '警用适航证',
            //   path: '/index/equipment/table',
            //   includes: [
            //     { path: '/index/equipment/table/tableOne' },
            //     { path: '/index/equipment/table/tableTwo' },
            //     { path: '/index/equipment/table/tableThree' },
            //     { path: '/index/equipment/table/tableFour' },
            //     { path: '/index/equipment/table/tableFive' },
            //     { path: '/index/equipment/table/tableSix' },
            //   ],
            // },
          ],
        },
        {
          name: '成果',
          img: require('@/assets/img/Icon/sidebar_model.png'),
          img_light: require('@/assets/img/Icon/sidebar_model_light.png'),
          img_press: require('@/assets/img/Icon/sidebar_model_press.png'),
          img_press_light: require('@/assets/img/Icon/sidebar_model_press_light.png'),
          path: '/index/resource/modal',
          includes: [
            {
              name: '全部',
              img: require('@/assets/img/Icon/sidebar_overview.png'),
              img_press: require('@/assets/img/Icon/sidebar_overview_press.png'),
              path: '/overview',
            },
            {
              name: '沙盘模型',
              path: '/index/resource',
              includes: [
                {
                  name: 'Terra首页',
                  path: '/resourceIndex/Terra',
                },
                {
                  name: '云计算选择绑定建图任务',
                  path: '/resourceIndex/establish',
                },
                {
                  name: '云计算上传页',
                  path: '/resourceIndex/uploadingPage',
                },
                // {
                //   name: '正射查看',
                //   path: '/resourceIndex/orthographicShou',
                // },
                {
                  name: '模型查看',
                  path: '/model/List',
                },
                {
                  name: '正射查看',
                  path: '/orthographicShou',
                },
              ],
            },
            {
              name: '二维地图',
              path: '/index/resource/ermodal',
              //   includes: [
              //     {
              //       name: '正射查看',
              //       path: '/resourceIndex/orthographicShou',
              //     },
              //   ],
            },
            {
              name: '快速拼图',
              path: '/index/resource/rapidMap',
              includes: [
                // { name: '快速对比', path: '/rapidMap/contrast' },
                { name: '快拼标注', path: '/rapidMap/label' },
                // {
                //   name: '保存截图',
                //   path: '/rapidMap/screenshot',
                // },
              ],
            },
            {
              name: '全景展示',
              path: '/index/resource/panorama',
              includes: [
                { name: '全景图编辑', path: '/index/edtiPanorama' },
                { name: '全景图详情', path: '/index/detailPanorama' },
                { name: '全景图管理', path: '/index/mgrPanorama' },
              ],
            },
            // 视频文件自由上传
            {
              name: '媒体资源',
              path: '/index/resource/media',
            },
          ],
        },

      ]
    }
    if (this.user_info.account === 'JFadmin' && this.$public) {
      this.MenuList.push({
        name: 'APP',
        img: require('@/assets/img/Icon/sidebar_app.png'),
        img_light: require('@/assets/img/Icon/sidebar_app_light.png'),
        img_press: require('@/assets/img/Icon/sidebar_app_press.png'),
        img_press_light: require('@/assets/img/Icon/sidebar_app_press.png'),
        path: '/index/apk',
      })
    }
    if(sessionStorage.getItem('sy_muen')==='true'){
      let data=  {
            name: '上云',
            img: require('@/assets/img/shangyun/shangyun.png'),
            img_press: require('@/assets/img/shangyun/shangyun.png'),
            path: '/shangyun/index',
            children: [
              {
                name: '实时监控',
                path: '/shangyun/live/watch-lists',
              },
              {
                name: '无人机直播',
                path: '/shangyun/live/live-lists',
              },
              {
                name: '航线',
                path: '/shangyun/lane/index',
              },
              {
                name: '资源',
                path: '/shangyun/resource/media',
              },
            ],
          }
      this.MenuList.push(data)
    }
    if (this.$SYC) {
      this.MenuList.push({
        name: '视频',
        img: require('@/assets/img/Icon/sidebar_tarckVideo.png'),
        img_light: require('@/assets/img/Icon/sidebar_tarckVideo_light.png'),
        img_press: require('@/assets/img/Icon/sidebar_tarckVideo_press.png'),
        img_press_light: require('@/assets/img/Icon/sidebar_tarckVideo_press.png'),
        path: '/trackVideo/index',
      })
    }
    // this.getUserData();
    // this.MenuList.forEach((ele) => {
    //   if (ele.name == '设备') {
    //     ele.path = '/index/equipment';
    //   }
    //   if (ele.name == '团队') {
    //     ele.path = '/index/team';
    //   }
    //   if (ele.name == '资源') {
    //     ele.path = '/index/resource';
    //   }
    //   if (ele.name == '设置') {
    //     ele.path = '/index/setting';
    //   }
    // });
    this.findActiveTab()

    // // 面包屑
    // this.$_bus.$off('layoutNav');
    // this.$_bus.$on('layoutNav', function (data) {
    //   _this.nav = data;
    // });
    //显示团队id
    this.nav = this.user_info.team_name

    this.EditUser.head_icon = this.user_info.head_icon
    this.EditUser.name = this.user_info.name
   // this.getTeamEtCustom()
  },

  //   computed: {
  //     isSuperAdmin() {
  //       return (
  //         JSON.parse(window.sessionStorage.getItem('user_info') || '{}').type ===
  //         0
  //       );
  //     },
  //   },
  updated() {},

  beforeDestroy() {
    // 关闭页面
    if (this.closeWebsocket) {
      console.log(this.closeWebsocket)
      this.websock.close()
      if (this.timer) {
        //停止发送websock数据
        clearInterval(this.timer)
      }
    }
    if (this.$public) {
      this.removeListener()
    }
  },

  destroyed() {},
}
</script>

<style scoped lang="scss">
#layout {
  min-width: 670px;
}
.layout {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  .header {
    border: 0;
    height: 50px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $xf_hei1_2;
    padding: 0 20px 0 14px;
    display: flex;
    justify-content: space-between;
    z-index: 300;
    /deep/.ivu-breadcrumb {
      a {
        color: $white;
        font-size: 18px;
      }
    }
    .header-left {
      display: flex;
      align-items: center;
      white-space: nowrap;
      img {
        height: 36px;
        width: auto;
        margin-right: 10px;
      }
      .divide {
        width: 1px;
        height: 30px;
        background-color: $xf_hei1_2;
        margin: 0 14px;
        line-height: 50px;
      }
      .entrance {
        // font-weight: bolder;
        color: #fff;
        font-size: 16px;
        margin-right: 14px;
        // cursor: pointer;
      }

      //   .entrance:hover {
      //     color: #007acc;
      //   }
    }
    .header-right {
      display: flex;
      align-items: center;
      .entranceJL {
        color: #fff;
        font-size: 16px;
        cursor: pointer;
        background: #2D8CF0;
        display: inline-block;
        padding: 3px 15px;
        border-radius:15px;
        margin-right: 50px;
      }
      .theme-btns {
        display: flex;
        div {
          width: 50px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          cursor: pointer;
          margin-right: 8px;
        }
      }
      .headImg {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        overflow: hidden;
        // display: flex;
        // justify-content: center;
        // align-items: center;

        cursor: pointer;
        > img {
          width: 100%;
          height: 100%;
        }
      }
      .incident-btn {
        width: 36px;
        height: 23px;
        margin-right: 14px;
        background-color: #023055;
        line-height: 23px;
        text-align: center;
        cursor: pointer;
      }
      .incident-btn:hover {
        background-color: $xf_hui5_hover;
      }
      .exit {
        width: 23px;
        height: 23px;
        background-color: #023055;
        cursor: pointer;
        > img {
          width: 13px;
          margin: 5px auto 0;
        }
      }
      .exit:hover {
        background-color: $xf_hui5_hover;
      }
      span {
        font-size: 14px;
        color: $white;
        margin-right: 20px;
      }
    }
  }
  .layoutSidebar {
    background-color: $white;
    flex: 1;
    display: flex;
    height: calc(100vh - 50px);
    // overflow: hidden;
    align-items: stretch;
    margin-top: 50px;
    .team-cascader {
      background: $xf_hui4;
      width: 30px;
      height: 100%;
      cursor: pointer;
      > img {
        display: block;
        margin: 23px auto 0;
      }
    }
    .team-cascader:hover {
      background: $xf_hui4_hover;
    }
    .disable-team {
      cursor: not-allowed;
    }
    .sidebar {
      flex: 0 0 70px;
      height: 100%;
      background-color: $xf_hui5;
      padding-top: 20px;
      border-right: 1px solid $black;
      border-left: 1px solid $black;
      overflow: auto;
      // z-index: 100000 !important;
      > div {
        width: 100%;
        height: 72px;
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        cursor: pointer;

        > div {
          width: 30px;
          //   height: 38px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
        }
        .public_item {
          //   transition: all 0.3s;//nmsl
          //   height: 24px;
          overflow: hidden;
          img {
            width: 24px;
            height: auto;
            // transform: translateY(10px);

            // transition: all 0.3s;
          }
          span {
            font-size: 14px;
            margin-top: 4px;
            color: $white;
            // visibility: hidden;
            // transform: translateY(50px);
            text-align: center;
            // transition: all 0.3s;
          }
        }
        // > .item:hover span,
        // > .item:hover img {
        //   transform: translateY(0);
        //   //   display: block;
        // }
        .item {
          transition: all 0.3s; //nmsl
          height: 60px;
          overflow: hidden;
          img {
            width: 24px;
            height: 24px;
            transform: translateY(10px);

            transition: all 0.3s;
          }
          span {
            font-size: 14px;
            margin-top: 4px;
            color: $black;
            // visibility: hidden;
            transform: translateY(50px);
            text-align: center;
            transition: all 0.3s;
          }
        }
        > .item:hover span,
        > .item:hover img {
          transform: translateY(0);
          display: block;
        }
        &:hover {
          .bar-child {
            display: inline-flex;
            z-index: 3000;
          }
        }
        .bar-child {
          z-index: -2;
          // opacity: 0;
          display: none;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          background-color: $xf_hui1;
          padding: 5px 5px 0;
          width: auto;
          height: auto;
          position: absolute;
          top: 0;
          left: 78px;
          transition: all 0.3s ease-in;
          border-radius: 4px;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          cursor: pointer;
          div {
            width: 100%;
            padding: 0 5px;
            height: 42px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #707070;
            white-space: nowrap;
            border-bottom: 1px solid rgba(112, 112, 112, 1);
            &:last-child {
              border-bottom: none;
            }
          }
          .active {
            color: #409eff;
          }
          &::before {
            content: '';
            width: 62px;
            height: 72px;
            position: absolute;
            left: -40px;
            top: 0;
          }
        }
      }
      // .select {
      //   // .item {
      //   //   span {
      //   //     color: #fff;
      //   //     font-size: 0.14rem;
      //   //   }
      //   // }
      // }
      .public_select {
        background-color: $black;
        border-left: 7px solid #2196f3;
        .item {
          span {
            color: $white;
            font-size: 0.14rem;
          }
        }
      }
    }
    .sidebar::-webkit-scrollbar {
      display: none;
    }
    .Content {
      background-color: $xf_hei2;
      height: calc(100vh - 50px);
      //   min-height: 700px;
      //   overflow-y: scroll;
      flex: 1;
      overflow: hidden;
      width: calc(100vw - 100px);
    }
    .Content::-webkit-scrollbar {
      display: none;
    }
  }
}
/deep/.EditUser-modal {
  .upload-list {
    display: inline-block;
    width: 80px;
    height: 80px;
    text-align: center;
    line-height: 80px;
    border: 1px solid transparent;
    border-radius: 4px;
    overflow: hidden;
    background: $white;
    position: relative;
    box-shadow: 0 3px 6px #eee;
    margin-right: 4px;
  }
  .upload-list img {
    width: 100%;
    height: 100%;
  }
  .upload-list-cover {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
  }
  .upload-list:hover .upload-list-cover {
    display: block;
  }
  .upload-list-cover i {
    color: $white;
    font-size: 20px;
    cursor: pointer;
    margin: 0 2px;
  }
  .bind-info {
    padding: 30px;
  }
}
.securityBox {
  position: relative;
  .security {
    cursor: pointer;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 120px;
    height: 32px;
    background-color: #2196f3;
    font-size: 14px;
    color: $white;
    text-align: center;
    line-height: 32px;

    span {
      font-size: 12px;
    }
  }

  .bgc {
    background-color: #97ccf8;
  }
}

.callvideo {
  text-align: center;
  position: fixed;
  z-index: 99999;
  width: 80px;
  height: 80px;
  right: 20px;
  top: 100px;
  border-radius: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.4s;
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  .video {
    margin: 10%;
    width: 80%;
    display: flex;
    // height: 40%;
    div {
      width: 50%;
    }
  }
  .callimg {
    img {
      width: 70px;
      height: 70px;
      margin: 5px;
      border-radius: 50%;
    }
  }
  button {
    width: 70px;
    height: 70px;
    margin: 5px;
    border-radius: 50%;
  }
  .callspan {
    margin: auto 0;
    span {
      text-align: center;
      display: block;
      width: 70px;
      font-size: 16px;
      color: $white;
    }
  }
}

.callvideo:hover {
  width: 240px;
}
/deep/ .ivu-drawer-header {
  background: $xf_hui1;
  border-bottom: 1px solid #000000;
}
.drawer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $font_color_1;

  > div {
    // width: 41px;
    padding: 0 5px;
    height: 25px;
    background: $xf_hui7;
    text-align: center;
    line-height: 25px;
    cursor: pointer;
  }
  > div:hover {
    background: $xf_hui7_hover;
  }
}
.drawer-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  border-top: 1px solid $black;
  background: $xf_hui5;
  color: $white;
  font-size: 14px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
}
.drawer-btn:hover {
  background: $xf_hui5_hover;
}
.onPhone {
  position: fixed;

  z-index: 9999;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #707070;
  width: 426px;
  height: 78px;
  background-color: $xf_hei2;
  color: $white;
  font-size: 16px;
  padding: 25px;
  animation: show1 0.5s;
  div {
    display: flex;
    justify-content: space-between;
  }
  img {
    width: 25px;
    height: 25px;
  }
}
@keyframes show1 {
  from {
    top: 0;
  }
}
</style>
