<template>
  <div id="independent" ref="independent" v-if="onPublish">
    <vue-drag-resize
      :isActive="false"
      :isDraggable="true"
      :isResizable="false"
      :w="500"
    >
      <!-- <div class="btn FullscreenIcon">
        <Icon
          type="md-expand"
          color="#fff"
          size="24"
          @click="fullScreen = !fullScreen"
        />
      </div> -->

      <!-- <div class="btn arrow" @click="taggle" v-if="false">
        <Icon type="ios-arrow-down" v-show="show" size="24" />
        <Icon type="ios-arrow-up" v-show="!show" size="24" />
      </div> -->
      <div class="topBar">
        <div class="btn close" @click="closeIndependent">
          <Icon type="md-close" color="#fff" size="24" />
        </div>
      </div>
      <div class="live-box" ref="live_video">
        <!-- <VideoItem :config="config"
                 :isWatch="true"
                 :showCtrl="false"
                 :independent="true"
                 ref="liveVideo" /> -->

        <div v-if="liveNvrUrl != ''" v-drag style="width: 100%; height: 100%">
          <iframe
            :src="liveNvrUrl"
            width="100%"
            height="100%"
            allowfullscreen
            allow="autoplay; fullscreen"
          ></iframe>
        </div>
        <div
          class="video"
          id="Independent"
          v-else
          style="background-color: #333333; width: 100%; height: 100%"
        ></div>

        <Spin size="large" fix v-if="spinShow"></Spin>
      </div>

      <div class="independent-info">
        <p>
          {{ config.account }} - <span>{{ config.teamName }}</span>
        </p>
        <span>{{ config.DRONEMODEL }}</span>
      </div>
    </vue-drag-resize>
  </div>
</template>

<script>
import Api from '@/utils/api.js'
import VideoItem from './live/watch/video-item.vue'
import VueDragResize from 'vue-drag-resize'
export default {
  name: 'independent-live',
  components: { VideoItem, VueDragResize },
  data() {
    return {
      config: {},
      onPublish: false,
      show: true,
      spinShow: true,
      player: null,
      liveNvrUrl: '',
      uid: 0,
      fullScreen: false,
    }
  },

  mounted() {
    let that = this
    this.$_bus.$off('showLiveHandler').$on('showLiveHandler', function (data) {
      if (that.onPublish) {
        that.onPublish = false
      }
      console.log(data)
      that.$nextTick(() => {
        that.onPublish = true
        that.config = data
        that.init()
      })
    })

    this.$_bus.$off('endLive').$on('endLive', function () {
      that.onPublish = false
    })
  },
  watch: {
    fullScreen(status) {
      // console.log(status);
      // console.log(document.exitFullscreen);
      if (status) {
        var ele = this.$refs.live_video
        // var ele=document.getElementById('live-video');
        if (ele.requestFullscreen) {
          ele.requestFullscreen()
        } else if (ele.mozRequestFullScreen) {
          ele.mozRequestFullScreen()
        } else if (ele.webkitRequestFullScreen) {
          ele.webkitRequestFullScreen()
        }
      } else {
        var de = document
        if (de.exitFullscreen) {
          de.exitFullscreen()
        } else if (de.mozCancelFullScreen) {
          de.mozCancelFullScreen()
        } else if (de.webkitCancelFullScreen) {
          de.webkitCancelFullScreen()
        }
      }
    },
    // FullscreenStatus(val) {
    //   console.log('FullscreenStatus', val);
    // },
  },
  methods: {
    taggle() {
      if (this.show) {
        this.show = false
        this.$refs['independent'].style.bottom = '0'
      } else {
        this.show = true
        this.$refs['independent'].style.bottom = '-374px'
      }
    },
    // 初始化video
    init() {
      var _this = this

      console.log(this.config.user_id)
      this.$post(Api.getLiveUrl(), {
        client: 'web',
        channel: 1,
        user_id: this.config.user_id,
      })
        .then((res) => {
          if (res.data) {
            this.shareUrl = this.sharebaseUrl + res.data.flv_url
            if (this.$online) {
              if (this.player != null) {
                this.player.destroy()
                this.player = null
              }
              if (this.$public) {
                this.player = new TcPlayer('Independent', {
                  // "m3u8": res.data.m3u8_url,

                  flv: this.$public ? res.data.UDP_url : res.data.flv_url,
                  webrtc: res.data.UDP_url,
                  // flv: this.$public ? res.data.UDP_url : res.data.flv_url,
                  // h5_flv: this.$public ? false : true,
                  //   rtmp: res.data.default_url,
                  live: true,
                  //   controls: 'none',
                  volume: 0,
                  autoplay: true, //iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
                  width: '100%', //视频的显示宽度
                  height: '100%', //视频的显示高度
                  listener: function (msg) {
                    if (msg.type == 'error') {
                      switch (msg.detail.code) {
                        case 1:
                          _this.$message.error(
                            '网络错误，请检查网络配置或者播放链接是否正确。'
                          )
                          break
                        case 2:
                          _this.$message.error('视频格式 Web 播放器无法解码。')
                          break
                        case 3:
                          _this.$message.error('视频解码错误。')
                          break
                        case 4:
                          _this.$message.error(
                            '当前系统环境不支持播放该视频格式。'
                          )
                          break
                        case 5:
                          _this.$message.error(
                            '播放器判断当前浏览器环境不支持播放传入的视频，可能是当前浏览器不支持 MSE 或者 Flash 插件未启用。'
                          )
                          break
                        case 13:
                          _this.$message.error('直播已结束，请稍后再来。')
                          break
                        case 1001:
                          _this.$message.error('断网了')
                          break
                        case 1002:
                          _this.$message.error(
                            '获取视频失败，请检查播放链接是否有效。'
                          )
                          break
                        case 2048:
                          _this.$message.error(
                            '无法加载视频文件，跨域访问被拒绝。'
                          )
                          break
                        default:
                          _this.$message.error('出错了')
                          break
                      }
                    }

                    //   if (msg.type == 'pause') {
                    //     console.log('pause');
                    //   }
                    if (msg.type == 'ended') {
                      if (_this.independent) {
                        _this.$Message.info('小窗直播已结束')
                        _this.player.destroy()
                        _this.player = null
                        _this.$_bus.$emit('endLive')
                      }
                    }
                    //   if (msg.type == 'seeking') {
                    //     console.log('seeking');
                    //   }
                    //   if (msg.type == 'seeked') {
                    //     console.log('seeked');
                    //   }
                    //   if (msg.type == 'resize') {
                    //     console.log('resize');
                    //   }
                  },
                })
              } else {
                this.player = new TcPlayer('Independent', {
                  // "m3u8": res.data.m3u8_url,

                  // flv: this.$public ? res.data.UDP_url : res.data.flv_url,
                  // webrtc: res.data.UDP_url,
                  flv: this.$public ? res.data.UDP_url : res.data.flv_url,
                  h5_flv: this.$public ? false : true,
                  //   rtmp: res.data.default_url,
                  live: true,
                  //   controls: 'none',
                  volume: 0,
                  autoplay: true, //iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
                  width: '100%', //视频的显示宽度
                  height: '100%', //视频的显示高度
                  listener: function (msg) {
                    if (msg.type == 'error') {
                      switch (msg.detail.code) {
                        case 1:
                          _this.$message.error(
                            '网络错误，请检查网络配置或者播放链接是否正确。'
                          )
                          break
                        case 2:
                          _this.$message.error('视频格式 Web 播放器无法解码。')
                          break
                        case 3:
                          _this.$message.error('视频解码错误。')
                          break
                        case 4:
                          _this.$message.error(
                            '当前系统环境不支持播放该视频格式。'
                          )
                          break
                        case 5:
                          _this.$message.error(
                            '播放器判断当前浏览器环境不支持播放传入的视频，可能是当前浏览器不支持 MSE 或者 Flash 插件未启用。'
                          )
                          break
                        case 13:
                          _this.$message.error('直播已结束，请稍后再来。')
                          break
                        case 1001:
                          _this.$message.error('断网了')
                          break
                        case 1002:
                          _this.$message.error(
                            '获取视频失败，请检查播放链接是否有效。'
                          )
                          break
                        case 2048:
                          _this.$message.error(
                            '无法加载视频文件，跨域访问被拒绝。'
                          )
                          break
                        default:
                          _this.$message.error('出错了')
                          break
                      }
                    }

                    //   if (msg.type == 'pause') {
                    //     console.log('pause');
                    //   }
                    if (msg.type == 'ended') {
                      if (_this.independent) {
                        _this.$Message.info('小窗直播已结束')
                        _this.player.destroy()
                        _this.player = null
                        _this.$_bus.$emit('endLive')
                      }
                    }
                    //   if (msg.type == 'seeking') {
                    //     console.log('seeking');
                    //   }
                    //   if (msg.type == 'seeked') {
                    //     console.log('seeked');
                    //   }
                    //   if (msg.type == 'resize') {
                    //     console.log('resize');
                    //   }
                  },
                })
              }
            } else {
              this.liveNvrUrl =
                'http://43.4.201.100:10800/play.html?channel=' +
                res.data.channel +
                '&iframe=yes&aspect=741x414'
            }
          }
        })
        .catch((err) => {
          console.log(err)
          this.$notice.error({
            title: '获取直播地址失败,返回无数据',
            desc: err.toString(),
          })
        })
        .finally(() => {
          this.spinShow = false
        })
    },
    closeIndependent() {
      this.onPublish = false
    },
  },
  beforeDestroy() {
    if (this.player !== null && this.liveNvrUrl == '') {
      //   this.player.pause();
      //   this.player.unload();
      //   this.player.detachMediaElement();
      //////
      this.player.destroy()
      this.player = null
      /////
      //   this.player.videoClear();
    }
  },
}
</script>

<style lang="scss">
#independent {
  position: fixed;
  bottom: 374px;
  right: 0px;
  width: 540px;
  z-index: 1500;
  background-color: #fff;
  transition: bottom 0.5s;
  .topBar {
    width: 100%;
    height: 20px;
    background: #333;
    position: relative;
    cursor: pointer;
  }
  .btn {
    position: absolute;
    z-index: 10;
    cursor: pointer;
  }
  .arrow {
    width: 60px;
    height: 25px;
    background-color: #fff;
    top: -25px;
    left: 15px;
    text-align: center;
    line-height: 30px;
    border-radius: 6px 6px 0 0;
  }
  .close {
    right: 0px;
    background-color: rgba(0, 0, 0, 0.7);
  }
  .FullscreenIcon {
    left: 15px;
    top: 12px;
    background-color: rgba(0, 0, 0, 0.7);
  }
  .live-box {
    width: 100%;
    height: 303px;
  }
  .independent-info {
    height: 64px;
    padding-top: 13px;
    padding-left: 16px;
    background: #fff;
    p {
      font-size: 14px;
      font-weight: 400;
      color: #000;
    }
    span {
      color: #606060;
      font-size: 12px;
    }
  }
}
</style>