<template>
  <div>
    <Tree :data="data3"
          :load-data="loadData"
          @on-select-change="selectTeam"></Tree>
  </div>
</template>

<script>
import Api from '@/utils/api.js';
export default {
  data() {
    return {
      data3: [],
      userInfo: {},
    };
  },
  mounted() {
    this.userInfo = JSON.parse(sessionStorage.getItem('user_info'));
    this.data3.unshift({
      title: this.userInfo.team_name,
      loading: false,
      children: [],
      team_id: this.userInfo.team_id,
      render: (h) => {
        return h(
          'div',
          {
            style: {
              width: '90%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            },
          },
          [
            h('span', this.userInfo.team_name),
            // $CSTJ
            h('Icon', {
              style: {
                display: this.$CSTJ ? 'inline-block' : 'none', // 如果公版需要团队单选功能,就放开判断,直接注释style,共有四处
              },
              props: {
                type: 'ios-contact',
                size: '22',
              },

              on: {
                click: (e) => {
                  e.stopPropagation();
                  sessionStorage.setItem('isEnable', 0);
                  sessionStorage.setItem('team_id', this.userInfo.team_id);
                  this.$_bus.$emit('change_Team', this.userInfo.team_id);
                },
              },
            }),
          ]
        );
      },
    });
  },
  methods: {
    loadData(item, callback) {
      const data = [];
      this.$post(Api.teamList(), {
        team_id: item.team_id,
      }).then((res) => {
        if (res.code == 1) {
          if (res.data[0].child_team.length > 0) {
            res.data[0].child_team.forEach((element) => {
              data.push({
                title: element.label,
                loading: false,
                children: [],
                team_id: element.value,
                up_team: element.up_team,
                render: (h, { root, node, data }) => {
                  return h(
                    'div',
                    {
                      style: {
                        width: '90%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      },
                    },
                    [
                      h('span', data.title),
                      h('Icon', {
                        props: {
                          type: 'ios-contact',
                          size: '22',
                        },
                        style: {
                          display: this.$CSTJ ? 'inline-block' : 'none',
                        },

                        on: {
                          click: (e) => {
                            e.stopPropagation();
                            sessionStorage.setItem('isEnable', 0);
                            sessionStorage.setItem('team_id', element.value);
                            this.$_bus.$emit('change_Team', element.value);
                          },
                        },
                      }),
                    ]
                  );
                },
              });
            });
            callback(data);
          } else {
            this.$Message.info('该团队无下级团队');
            callback(data);
          }
        } else {
          this.$Message.error(res.msg_customer);
        }
      });
    },
    // 选中团队
    selectTeam(treeArr, teamData) {
      sessionStorage.setItem('isEnable', 1);
      // console.log("treeArr", treeArr, teamData.team_id);
      let value = this.userInfo.team_id;

      /**
       * 如果公版需要团队单选功能,就放开!this.$CSTJ判断
       * 即value的初始值仅在this.$route.path == '/live/watch-lists'和'/live/live-lists'时为-1,其他均为this.userInfo.team_id
       */
      if (
        this.$route.path == '/live/watch-lists' ||
        this.$route.path == '/live/live-lists' ||
        !this.$CSTJ
      ) {
        value = -1;
      }

      //   if (treeArr.length > 0) {
      //     value = teamData.team_id;
      //   } else if (treeArr[0] == 'self') {
      //     value = this.userInfo.team_id;
      //   }
      if (treeArr[0] == 'self') {
        value = this.userInfo.team_id;
      } else if (treeArr.length > 0) {
        value = teamData.team_id;
      }
      //   this.$emit('change_Team', value, selectedData);
      sessionStorage.setItem('team_id', value);
      this.$_bus.$emit('change_Team', value);
      // console.log(teamData.team_id);
    },
    clearUp() {
      this.selectTeam([], '');
      this.data3 = [];
      this.data3.unshift({
        title: this.userInfo.team_name,
        loading: false,
        children: [],
        team_id: this.userInfo.team_id,
        render: (h) => {
          return h(
            'div',
            {
              style: {
                width: '90%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              },
            },
            [
              h('span', this.userInfo.team_name),
              h('Icon', {
                props: {
                  type: 'ios-contact',
                  size: '22',
                },
                style: {
                  display: this.$CSTJ ? 'inline-block' : 'none',
                },
                on: {
                  click: (e) => {
                    e.stopPropagation();
                    console.log(this.userInfo.team_id);
                    sessionStorage.setItem('isEnable', 0);
                    sessionStorage.setItem('team_id', this.userInfo.team_id);
                    this.$_bus.$emit('change_Team', this.userInfo.team_id);
                    this.$_bus.$emit('change_Team', -1);
                  },
                },
              }),
            ]
          );
        },
      });
    },
    self() {
      this.selectTeam(['self'], '');
      this.data3 = [];
      this.data3.unshift({
        title: this.userInfo.team_name,
        loading: false,
        children: [],
        team_id: this.userInfo.team_id,
        render: (h) => {
          return h(
            'div',
            {
              style: {
                width: '90%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              },
            },
            [
              h('span', this.userInfo.team_name),
              h('Icon', {
                props: {
                  type: 'ios-contact',
                  size: '22',
                },
                style: {
                  display: this.$CSTJ ? 'inline-block' : 'none',
                },
                on: {
                  click: (e) => {
                    e.stopPropagation();
                    sessionStorage.setItem('isEnable', 0);
                    sessionStorage.setItem('team_id', this.userInfo.team_id);
                    this.$_bus.$emit('change_Team', this.userInfo.team_id);
                  },
                },
              }),
            ]
          );
        },
      });
    },
    //
    // clearSelect() {
    //   let Items = document.getElementsByClassName('ivu-tree-title-selected');
    //   for (let i = 0; i < Items.length; i++) {
    //     Items[i].classList.remove('ivu-tree-title-selected');
    //   }
    // },
  },
};
</script>

<style scoped lang="scss">
.ivu-tree /deep/ {
  overflow: hidden;
  ul {
    padding-left: 15px;
    line-height: 43px;
    background-color: $xf_hui1;
  }
  .ivu-tree-title {
    /* display: unset; */
    width: 100%;
    color: $font_color_1;
  }
  .ivu-tree-title:hover {
    box-shadow: -80px 0 0 $xf_hui4_hover;
    background-color: $xf_hui4_hover;
  }
  .ivu-tree-title-selected,
  .ivu-tree-title-selected:hover {
    box-shadow: -80px 0 0 $xf_hui6;
    background-color: $xf_hui6;
  }
  .ivu-tree-arrow {
    position: relative;
    width: 20px;
  }
  .ivu-tree-arrow i {
    vertical-align: text-bottom;
    font-size: 20px;
    color: $white;
    .ivu-tree-empty {
      text-align: center;
      margin-top: 30px;
      font-size: 14px;
      color: $font_color_1;
    }
  }
}
</style>
